<template>
  <div>
    <v-app-bar
      id="app-bar"
      absolute
      app
      color="#e0e0e044"
      outlined
      flat
      height="75"
      style=""
    >
      <a @click="$router.push({ name: 'Home' })" class="mr-4 d-flex">
        <img src="@/assets/logo/Logo-Morado.svg" height="25px" />
      </a>
      <navbar-responsive-menu />
    </v-app-bar>
    <!-- <menu-vertical></menu-vertical> -->
  </div>
</template>

<script>
// Components

import NavbarResponsiveMenu from "@/components/mylogy/NavbarResponsiveMenu.vue";
// Utilities

import appbase from "@/services/appbase.js";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
// import MenuVertical from "@/components/mylogy/MenuVertical.vue";

export default {
  name: "DashboardCoreAppBar",

  components: {
    NavbarResponsiveMenu
    // MenuVertical
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({}),

  computed: {
    ...mapState(["drawer"]),
    ...mapGetters("auth", ["strapiUserData"]),
    ...mapGetters("app", ["allDataLoaded"])
  },
  async created() {
    await this.refreshUserData();
    if (!this.allDataLoaded) {
      this.$store.commit("auth/SET_AUTH_LOADING", true);
      await this.getMySampleData();
      this.changeHelpDialogVisibility(true);
      await Promise.allSettled([
        this.getDrugs(),
        this.getPgxGenes(),
        this.getDrugCategories(),
        this.getPgxGenesSubs(),
        this.getFAQs()
      ]);
      this.setAllDataLoaded(true);
    }
    this.$store.commit("auth/SET_AUTH_LOADING", false);
  },
  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER"
    }),
    ...mapActions("app", ["changeHelpDialogVisibility"]),
    ...mapActions("gxSample", ["getMySampleData"]),
    ...mapActions("drugs", ["getDrugs"]),
    ...mapActions("faqs", ["getFAQs"]),
    ...mapActions("pgxGenes", ["getPgxGenes", "getPgxGenesSubs"]),
    ...mapActions("drugCategories", ["getDrugCategories"]),
    ...mapActions("auth", ["refreshUserData"]),
    ...mapActions("app", ["setAllDataLoaded"]),
    loadAllDrugs() {
      return appbase.drugs.search({
        body: {
          size: 2000,
          query: {
            bool: {
              must: [
                {
                  term: {
                    "card.keyword": "y"
                  }
                }
              ]
            }
          },
          _source: [
            "es",
            "po",
            "en",
            "atc_2_EN",
            "atc_2_PO",
            "atc_2_ES",
            "substrate_of"
          ]
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#app-bar {
  backdrop-filter: blur(40px);
}
</style>
