<template>
  <div id="navbar-responsive-menu">
    <!-- Menu desktop -->
    <div class="d-none d-md-flex">
      <v-row class="d-flex" justify="space-around" align="center">
        <div v-for="item in menuItems" :key="item.to" class="underlined">
          <a
            color="primary"
            @click="$router.push({ name: item.to, params: item.params })"
            :class="{ 'active-route': $route.name == item.to }"
            class="font-weight-regular"
            >{{ $t(item.text) }} <span class="underline" style=""></span
          ></a>
        </div>
      </v-row>

      <v-spacer></v-spacer>

      <div class="d-flex float-right mt-2">
        <language-selector />
        <v-menu
          bottom
          left
          min-width="200"
          offset-y
          origin="top right"
          transition="scale-transition"
          z-index="99"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
              <v-icon>mdi-account</v-icon>
              <span> ID: {{ sampleNumber }} </span>
            </v-btn>
          </template>

          <v-list :tile="false" flat>
            <template v-for="(p, i) in profile">
              <v-divider
                v-if="p.divider"
                :key="`divider-${i}`"
                class="mb- mt-2"
              />
              <app-bar-item
                v-on:click.native="menuClick(p.title)"
                v-else
                :key="`item-${i}`"
              >
                <v-list-item-title v-text="p.title" />
              </app-bar-item>
            </template>
          </v-list>
        </v-menu>
      </div>
    </div>

    <!-- Menu phone movil telefono -->
    <div class="hidden-md-and-up text-right">
      <v-btn icon @click.native="dialog = true">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <v-dialog
        v-model="dialog"
        fullscreen
        transition="dialog-bottom-transition"
      >
        <base-material-card>
          <template v-slot:after-heading>
            <v-btn icon @click.native="dialog = false" class="mt-4">
              <v-icon large color="red">mdi-close</v-icon>
            </v-btn>
          </template>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-list-item v-for="(item, index) in menuItems" :key="index">
                  <v-list-item-content
                    @click="
                      $router.push({ name: item.to, params: item.params })
                    "
                    class="text-h2 primary--text"
                  >
                    <v-list-item-title class="text-h3 font-weight-thin">{{
                      item.text
                    }}</v-list-item-title>
                    <v-divider></v-divider>
                  </v-list-item-content>
                </v-list-item>
              </v-row>
            </v-container>
            <v-row justify="center">
              <v-col cols="4">
                <language-selector />
              </v-col>
              <v-col cols="7" class="align-self-center">
                <v-menu
                  bottom
                  left
                  offset-y
                  origin=""
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-row>
                      <v-btn
                        class="ml-2"
                        min-width="0"
                        text
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-account</v-icon>
                        <span> ID: {{ sampleNumber }} </span>
                      </v-btn>
                    </v-row>
                  </template>

                  <v-list :tile="false" flat nav>
                    <template v-for="(p, i) in profile">
                      <v-divider
                        v-if="p.divider"
                        :key="`divider-${i}`"
                        class="mb-2 mt-2"
                      />
                      <app-bar-item
                        v-on:click.native="menuClick(p.title)"
                        v-else
                        :key="`item-${i}`"
                      >
                        <v-list-item-title v-text="p.title" />
                      </app-bar-item>
                    </template>
                  </v-list>
                  <v-list :tile="false" flat nav>
                    <template v-for="(p, i) in profile">
                      <v-divider
                        v-if="p.divider"
                        :key="`divider-${i}`"
                        class="mb-2 mt-2"
                      />
                      <app-bar-item
                        v-on:click.native="menuClick(p.title)"
                        v-else
                        :key="`item-${i}`"
                      >
                        <v-list-item-title v-text="p.title" />
                      </app-bar-item>
                    </template>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "@/services/axios.js";
import { VHover, VListItem } from "vuetify/lib";
import LanguageSelector from "@/components/mylogy/LanguageSelector.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    LanguageSelector,
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs
                  }
                },
                this.$slots.default
              );
            }
          }
        });
      }
    }
  },
  data() {
    return {
      dialog: false,
      locale: this.$i18n.locale,
      langs: [
        { lang: "ES", code: "es" },
        { lang: "PT", code: "po" },
        { lang: "EN", code: "en" }
      ]
    };
  },
  computed: {
    ...mapGetters("auth", ["strapiUserData"]),
    ...mapGetters("gxSample", ["sampleNumber"]),
    profile() {
      let self = this;
      return [
        { title: self.$t("cuenta"), visible: true },
        {
          title: self.$t("subscripcion"),
          visible: ["advanced", "pro", "basic"].includes(
            self.strapiUserData.type
          )
        },
        { divider: true, visible: true },
        { title: self.$t("log_out"), visible: true }
      ].filter(e => e.visible);
    },
    menuItems() {
      let self = this;
      return [
        {
          to: "GeneticProfile",
          params: {},
          text: self.$t("perfil_genetico"),
          active: true,
          visible: ["advanced", "pro", "basic"].includes(
            self.strapiUserData.type
          )
        },
        {
          to: "PGXResults",
          params: {},
          text: self.$t("farmacogenetica"),
          active: false,
          visible: ["advanced", "pro", "basic"].includes(
            self.strapiUserData.type
          )
        },
        {
          to: "Explorer",
          params: { app: "kb-drugs" },
          text: self.$t("wpgx"),
          active: false,
          visible: true
        },
        {
          to: "InfoPage",
          text: "FAQ",
          active: false,
          visible: true
        }
      ].filter(e => e.visible);
    }
  },
  methods: {
    goToDashboard() {
      let self = this;
      return axios
        .post("/.netlify/functions/customer-portal", {})
        .then(function(resp) {
          window.location.href = resp.data.url;
        });
    },
    menuClick(title) {
      const self = this;
      if (title == self.$t("cuenta")) {
        return self.$router.push({ name: "UserAccount" });
      } else if (title == self.$t("subscripcion")) {
        return self.$router.push({ name: "SubscriptionPlans" });
      } else if (title == self.$t("log_out")) {
        localStorage.removeItem("redirect");
        this.$store.dispatch("auth/logout");
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
#navbar-responsive-menu {
  width: 100%;

  a {
    font-size: 20px;
    border-bottom: solid transparent 2px;
    transition: 0.3s;
  }

  a:hover {
    border-bottom: solid 2px;
  }

  .active-route {
    transition: 0.3s;
    border-bottom: solid 2px;
  }
}

.v-card__text {
  height: calc(100vh - 41px);
  align-content: center;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
}
.v-list-item.theme--light {
  max-width: 100%;

  .v-list-item__title {
    text-overflow: unset;
    white-space: normal;
  }
}

.language-selector {
  border: solid 1.5px;
  border-radius: 20px;
}
</style>
